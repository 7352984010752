<template>
  <v-col>
    <v-row v-if="showBatchInfoItems">
      <v-col>
        <v-row>
          <v-col>
            <v-text-field
              @click:clear="
                batchInfoItemSearch = null;
                getBatchInfoItems();
              "
              v-debounce:300="getBatchInfoItems"
              prepend-icon="search"
              v-model="batchInfoItemSearch"
              label="Search (UPC, item, desc)"
              :clearable="true"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-row align="start" justify="start">
              <v-col cols="12" xl="3" lg="3" md="3">
                <starting-position-picker v-model="startingPosition" class="pt-6" />
              </v-col>
              <v-col cols="12" xl="3" lg="3" md="3" v-if="showApprovedFilter">
                <v-combobox
                  prepend-icon="playlist_add_check"
                  v-model="selectedApprovedTypes"
                  :items="approvedTypes"
                  @blur="changeApproved(true)"
                  label="Approved"
                  multiple
                  chips
                  autocomplete="off"
                >
                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <v-chip v-bind="attrs" :input-value="selected" label>
                      <span class="pr-2">{{ item }}</span>
                      <v-icon small @click="parent.selectItem(item)">close</v-icon>
                    </v-chip>
                  </template>
                </v-combobox>
              </v-col>
              <v-col cols="12" xl="3" lg="3" md="3">
                <v-combobox
                  prepend-icon="playlist_add_check"
                  v-model="selectedSvgTemplates"
                  :items="svgTemplatesForBatch"
                  @blur="getBatchInfoItems(true)"
                  :label="`${labelTextInitalCaps} Types`"
                  multiple
                  chips
                  item-text="description"
                  item-value="templateId"
                >
                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <v-chip v-bind="attrs" :input-value="selected" label>
                      <span class="pr-2">{{ item.description }}</span>
                      <v-icon small @click="parent.selectItem(item)">close</v-icon>
                    </v-chip>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
            <v-row align="start" justify="start">
              <v-col v-if="allowRemove">
                <v-dialog v-model="deleteItemsDialog" max-width="400">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click="deleteItemsDialog = true"
                      v-on="on"
                      :disabled="deleteItemsDisabled"
                      >Remove Selected {{ labelTextInitalCaps + deleteItemsPlural }}</v-btn
                    >
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline"
                        >Remove {{ labelTextLowerCase + deleteItemsPlural }}?</span
                      >
                    </v-card-title>
                    <v-card-text>
                      Are you sure you want to remove {{ batchInfoItems.selected.length }}
                      {{ labelTextLowerCase + deleteItemsPlural }}?
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        @click="removeSelectedItems"
                        :loading="deleteItemsLoading"
                        :disabled="deleteItemsLoading"
                        >Remove</v-btn
                      >
                      <v-btn @click="deleteItemsDialog = false">Cancel</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
              <v-col v-if="showChangeAllLabelTypesButton">
                <v-dialog v-model="changeLabelTypesDialog" max-width="600">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      @click="changeLabelTypesDialog = true"
                      v-on="on"
                      :disabled="changeLabelTypeDisabled"
                      >{{ changeLabelTypeText }}</v-btn
                    >
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">Change {{ labelTextInitalCaps }} Type</span>
                    </v-card-title>
                    <v-card-text>
                      <v-select
                        v-model="changedLabelType"
                        item-text="description"
                        item-value="templateId"
                        :items="svgTemplates"
                        @change="changeAllTemplateChange"
                      ></v-select>
                      <span v-if="showChangeAllBulkTemplateMessage"
                        >⚠️You have chosen a bulk print type. We will only change bulk items to this
                        print type, all other items will stay the same.</span
                      >
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        @click="changeLabelTypes"
                        :disabled="changeLabelTypeDisabled"
                        :loading="changeLabelTypeLoading"
                        >Save</v-btn
                      >
                      <v-btn
                        :disabled="changeLabelTypeDisabled"
                        :loading="changeLabelTypeLoading"
                        @click="changeLabelTypesDialog = false"
                        >Cancel</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>

              <v-col v-if="showSetApprovedOnHand">
                <v-dialog v-model="setApprovedOnHandDialog" max-width="500">
                  <template v-slot:activator="{ on }">
                    <v-btn @click="setApprovedOnHandDialog = true" v-on="on"
                      >Add On-Hand Items To Approved</v-btn
                    >
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">Add On-Hand Items To Approved</span>
                    </v-card-title>
                    <v-card-text>
                      <p>
                        Are you sure you want to add on-hand items to approved? Items will remained
                        approved everytime you print this batch.
                      </p>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        @click="setApprovedOnHand"
                        :disabled="setApprovedOnHandDialogLoading"
                        :loading="setApprovedOnHandDialogLoading"
                        >Yes</v-btn
                      >
                      <v-btn
                        :disabled="setApprovedOnHandDialogLoading"
                        :loading="setApprovedOnHandDialogLoading"
                        @click="setApprovedOnHandDialog = false"
                        >No</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>

              <v-col v-if="showCheckForDiscounts">
                <v-dialog v-model="checkForDiscountsDialog" max-width="500">
                  <template v-slot:activator="{ on }">
                    <v-btn @click="checkForDiscountsDialog = true" v-on="on"
                      >Check for Discounts</v-btn
                    >
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">Check for discounts</span>
                    </v-card-title>
                    <v-card-text>
                      <p>
                        Are you sure you want to check for discounts? Any item on-sale will be
                        changed to it's discount print type.
                      </p>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        @click="checkForDiscountsForBatch"
                        :disabled="checkForDiscountsDialogLoading"
                        :loading="checkForDiscountsDialogLoading"
                        >Yes</v-btn
                      >
                      <v-btn
                        :disabled="checkForDiscountsDialogLoading"
                        :loading="checkForDiscountsDialogLoading"
                        @click="checkForDiscountsDialog = false"
                        >No</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>

              <v-col>
                <v-dialog v-model="resetTemplatesDialog" max-width="500">
                  <template v-slot:activator="{ on }">
                    <v-btn @click="resetTemplatesDialog = true" v-on="on"
                      >Reset Print Types To Original</v-btn
                    >
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">Reset Templates To Original</span>
                    </v-card-title>
                    <v-card-text>
                      <p>Are you sure you want to reset all items to their original template?</p>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        @click="resetTemplatesForBatch"
                        :disabled="resetTemplatesDialogLoading"
                        :loading="resetTemplatesDialogLoading"
                        >Yes</v-btn
                      >
                      <v-btn
                        :disabled="resetTemplatesDialogLoading"
                        :loading="resetTemplatesDialogLoading"
                        @click="resetTemplatesDialog = false"
                        >No</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row justify="end">
          <label-preview-switch></label-preview-switch>
        </v-row>

        <v-row class="batch-items">
          <v-col cols="12">
            <v-data-table
              v-if="batchInfoItems"
              v-model="batchInfoItems.selected"
              :headers="batchInfoItems.headers"
              :items="batchInfoItems.items"
              :single-select="false"
              :loading="batchInfoItems.loading"
              :fixed-header="true"
              :options.sync="batchInfoItems.options"
              :disable-pagination="true"
              @update:sort-by="batchInfoItems.get(batchInfoItemsAdditionalFilter())"
              item-key="batchInfoItemId"
              class="elevation-1 scrollable"
              show-select
            >
              <template v-slot:body.append>
                <infinite-loader
                  :pogonaTable="batchInfoItems"
                  :additionalFilter="batchInfoItemsAdditionalFilter()"
                  :colspan="batchInfoItems.headers.length"
                ></infinite-loader>
              </template>

              <template v-slot:header.data-table-select>
                <v-checkbox
                  v-model="batchInfoItems.allSelected"
                  :indeterminate="batchInfoItems.allSelectedIndeterminate"
                  @change="selectBatchInfoItemsAll"
                ></v-checkbox>
              </template>

              <template v-slot:item.signLabelRetail="{ item }">
                <v-edit-dialog
                  :return-value.sync="item.signLabelRetail"
                  @save="saveSignLabelRetailEdit(item)"
                  @open="openPriceEdit(item)"
                  @close="closePriceEdit(item)"
                  @cancel="closePriceEdit(item)"
                >
                  <span class="underline">{{
                    item.signLabelRetail | formatDecimalZeroIfNull
                  }}</span>
                  <template v-slot:input>
                    <v-text-field
                      :value="$filters.formatDecimalZeroIfNull(item.signLabelRetail)"
                      @change="item.signLabelRetail = $event"
                      :rules="signLabelRules"
                      label="Edit"
                      single-line
                      number
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>

              <template v-slot:item.discountAmount="{ item }">
                <v-edit-dialog
                  :return-value.sync="item.discountAmount"
                  @save="saveDiscountAmountEdit(item)"
                  @open="openPriceEdit(item)"
                  @close="closePriceEdit(item)"
                  @cancel="closePriceEdit(item)"
                >
                  <span class="underline">{{ item.discountAmount | formatDecimalZeroIfNull }}</span>
                  <template v-slot:input>
                    <v-text-field
                      :value="$filters.formatDecimalZeroIfNull(item.discountAmount)"
                      @change="item.discountAmount = $event"
                      :rules="discountRules"
                      label="Edit"
                      single-line
                      number
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>

              <template v-slot:item.discountPercent="{ item }">
                <v-edit-dialog
                  :return-value.sync="item.discountPercent"
                  @save="saveDiscountPercentEdit(item)"
                  @open="openPriceEdit(item)"
                  @close="closePriceEdit(item)"
                  @cancel="closePriceEdit(item)"
                >
                  <span class="underline">
                    {{ item.discountPercent | formatPercentZeroIfNull }}</span
                  >
                  <template v-slot:input>
                    <v-text-field
                      :value="$filters.formatDecimalZeroIfNull(item.discountPercent)"
                      @change="item.discountPercent = $event"
                      :rules="discountRules"
                      label="Edit"
                      single-line
                      number
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>

              <template v-slot:item.discountDescription="{ item }">
                <span
                  @click="
                    item.discountSvgTemplateId
                      ? svgTemplateChanged(item.discountSvgTemplateId, item)
                      : () => {}
                  "
                  :class="item.discountSvgTemplateId ? 'underline' : ''"
                  >{{ item.discountDescription }}</span
                >
              </template>

              <template v-slot:item.preview="{ item }">
                <label-preview-lazy
                  v-model="item.previewIsActive"
                  :item="item"
                  :url="`//${$apiBasePath}label-render/render-batch-label/${item.batchInfoId}/${item.batchInfoItemId}/${username}/${item.svgTemplateId}.svg`"
                  :headers="labelPreviewHeaders"
                  :disabled="changingBatchItem"
                  :loading="item.previewIsLoading"
                ></label-preview-lazy>
              </template>

              <template v-slot:item.svgTemplateId="{ item }">
                <v-select
                  v-model="item.svgTemplateIdInternal"
                  item-text="description"
                  item-value="templateId"
                  :items="svgTemplates"
                  style="min-width: 150px"
                  @change="svgTemplateChanged($event, item)"
                ></v-select>
              </template>

              <template v-slot:item.presentationQty="props">
                <v-edit-dialog
                  :return-value.sync="props.item.presentationQty"
                  @save="savePresentationQtyEdit(props.item)"
                  @open="openPresentationQtyEdit(props.item)"
                  @close="closePresentationQtyEdit(props.item)"
                  @cancel="closePresentationQtyEdit(props.item)"
                >
                  <span class="underline">{{ props.item.presentationQty }}</span>
                  <template v-slot:input>
                    <v-text-field
                      v-model="props.item.presentationQty"
                      :rules="presentationQtyRules"
                      label="Edit"
                      single-line
                      number
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>

              <template v-slot:item.approved="{ item }">
                <v-checkbox v-model="item.approved" readonly></v-checkbox>
              </template>
            </v-data-table>
            <infinite-paganation :pogonaTable="batchInfoItems"></infinite-paganation>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-row align="start" justify="start">
              <v-col xl="3" lg="3" md="3" cols="12">
                <v-btn
                  @click="submitPrint"
                  :loading="printing"
                  :disabled="printDisabled"
                  class="mr-4 green"
                  >Next</v-btn
                >
              </v-col>
              <v-col xl="9" lg="9" md="9" cols="12" v-if="showIncludeSetDelimtersCheckbox">
                <v-row>
                  <v-checkbox
                    v-model="includeSetDelimtersOverride"
                    label="Include POG Header(s)"
                  ></v-checkbox>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                v-if="isLabelAdmin"
                class="admin-icon"
                title="Force render is a label admin function."
              >
                <v-row>
                  <force-render-checkbox
                    prepend-icon="supervised_user_circle"
                    v-model="forceNewRender"
                  ></force-render-checkbox>
                </v-row>
              </v-col>
            </v-row>
            <v-row align="start" justify="start" v-if="overrideRetailStartDate">
              <v-row>
                <v-col>
                  <retail-start-date-dialog
                    v-model="retailStartDate"
                    :startDateDialog.sync="overrideStartDateDialog"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" :class="overrideStartDate ? 'green' : ''">
                        Override Retail Start Date{{
                          overrideStartDate
                            ? ` (${$options.filters.formatDate(retailStartDate)})`
                            : ''
                        }}
                      </v-btn>
                    </template>

                    <template>
                      <v-checkbox v-model="overrideStartDate" class="mt-2 ml-3 mx-auto">
                        <template v-slot:label>
                          <label @click.stop="overrideStartDate = !overrideStartDate"
                            >Override retail start date</label
                          >
                        </template>
                      </v-checkbox>
                    </template>

                    <template v-slot:actions>
                      <v-btn @click="overrideStartDateDialog = false">Close</v-btn>
                    </template>
                  </retail-start-date-dialog>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-dialog
                    v-model="showOverrideRetailStartDateWarning"
                    max-width="700px"
                    persistent
                  >
                    <v-card>
                      <v-card-title class="headline">
                        Are you sure you want to override the retail start date?
                      </v-card-title>
                      <v-card-text
                        >This feature is intended when printing a very old batch. It is not needed
                        everytime you print a batch. All batch are prices pre-calculated, updated
                        daily, and normally do not need to be overriden.</v-card-text
                      >
                      <v-card-actions>
                        <v-btn
                          @click="
                            overrideStartDate = false;
                            showOverrideRetailStartDateWarning = false;
                          "
                          >No</v-btn
                        >
                        <v-btn @click="showOverrideRetailStartDateWarning = false">Yes</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-row>
          </v-col>
        </v-row>
        <v-row :v-if="zeroItemsDialog">
          <v-col>
            <v-dialog max-width="340" v-model="zeroItemsDialog">
              <v-card>
                <v-card-title>
                  <span class="headline">Zero {{ labelTextInitalCaps }}s</span>
                </v-card-title>
                <v-card-text>
                  Zero {{ labelTextLowerCase }}s will be printed. Please make sure some items have
                  been selected.
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="zeroItemsDialog = false">Ok</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row :v-if="bulkTemplateDialog">
          <v-col>
            <v-dialog max-width="600" v-model="bulkTemplateDialog">
              <v-card>
                <v-card-title>
                  <span class="headline">Cannot change to bulk sign</span>
                </v-card-title>
                <v-card-text>
                  This item does not support this sign type. Choose the PLU for a bulk item (a UPC
                  that begins with 6 zeros), when choosing a bulk sign.
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="bulkTemplateDialog = false">Ok</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapState } from 'vuex';
import f from 'odata-filter-builder';
import uuid from 'uuid';
import Vue from 'vue';
import pLimit from 'p-limit';
import Router from '@/router';
import RenderLabelData from '@/data/SignsAndLabels/RenderLabelData';
import FooterInformation from '@/data/SignsAndLabels/FooterInformation';
import LabelPreviewLazy from './LabelPreviewLazy.vue';
import LabelPreviewSwitch from './LabelPreviewSwitch.vue';
import ForceRenderCheckbox from './ForceRenderCheckbox.vue';
import StartingPositionPicker from './StartingPositionPicker.vue';
import RetailStartDateDialog from './RetailStartDateDialog.vue';
import PogonaDataTable from '@/utils/PogonaDataTable';
import BatchCheckItems from '@/utils/BatchCheckItems';
import PogonaWebSocket from '@/utils/PogonaWebSocket';
import AdalHelpers from '@/utils/AdalHelpers';

const BatchCheckItemsComponent = Vue.component('BatchCheckItems', {
  components: {
    ForceRenderCheckbox,
    LabelPreviewLazy,
    StartingPositionPicker,
    RetailStartDateDialog,
    LabelPreviewSwitch,
  },
  props: {
    value: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
    batchInfo: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
    footerBatchAndId: {
      type: String,
      required: true,
      default: '',
    },
    additionalCompleteUpdate: {
      type: Object,
      required: false,
      default: null,
    },
    includeSetDelimters: {
      type: Boolean,
      required: false,
      default: true,
    },
    autoCompleteBatch: {
      type: Boolean,
      required: false,
      default: false,
    },
    showCompleteToggle: {
      type: Boolean,
      required: false,
      default: true,
    },
    overrideRetailStartDate: {
      type: Boolean,
      required: false,
      default: false,
    },
    overrideStoreNumber: {
      type: String,
      required: false,
      default: null,
    },
    allowRemove: {
      type: Boolean,
      required: false,
      default: true,
    },
    pageBreakOnNewStoreSet: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideBatchNameInFooter: {
      type: Boolean,
      required: false,
      default: false,
    },
    originatingPage: {
      type: String,
      required: true,
      default: null,
    },
    labelText: {
      type: String,
      required: false,
      default: 'Label',
    },
    bulkOnlyLabelTypes: {
      type: Boolean,
      required: false,
      default: null,
    },
    showIncludeSetDelimtersCheckbox: {
      type: Boolean,
      required: false,
      default: false,
    },
    showSetApprovedOnHand: {
      type: Boolean,
      required: false,
      default: false,
    },
    showCheckForDiscounts: {
      type: Boolean,
      required: false,
      default: false,
    },
    showApprovedFilter: {
      type: Boolean,
      required: false,
      default: false,
    },
    showChangeAllLabelTypesButton: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      startingPosition: 1,
      printing: false,
      svgTemplates: [],
      batchInfoItemSearch: null,
      selectedApprovedTypes: this.showApprovedFilter ? [] : ['Yes', 'No'],
      approvedTypes: ['Yes', 'No'],
      svgTemplatesForBatch: [],
      selectedSvgTemplates: [],
      batchInfoItems: null,
      forceNewRender: false,
      batchItemEditBefore: null,
      presentationQtyEditOpen: false,
      presentationQtyEditValid: false,
      zeroItemsDialog: false,
      includeSetDelimtersOverride: false,
      setApprovedOnHandDialog: false,
      setApprovedOnHandDialogLoading: false,
      resetTemplatesDialog: false,
      resetTemplatesDialogLoading: false,
      bulkTemplateDialog: false,
      showChangeAllBulkTemplateMessage: false,
      checkForDiscountsDialog: false,
      checkForDiscountsDialogLoading: false,
      presentationQtyRules: [
        v => {
          if (isNaN(v) === true || !v || v.toString().trim().length === 0) {
            return 'Presentation quantity must be a number.';
          }

          if (parseInt(v, 10) === 0) {
            return 'Presentation quantity must be greater than zero.';
          }

          if (parseInt(v, 10) > 100) {
            return 'Presentation quantity must be less than 100.';
          }

          return true;
        },
      ],
      signLabelRules: [
        v => {
          if (isNaN(v) === true) {
            return 'Retail price must be number.';
          }

          if (Number(v) <= 0) {
            return 'Retail price must be greater than zero.';
          }

          return true;
        },
      ],
      discountRules: [
        v => {
          if (isNaN(v) === true) {
            return 'Discount must be number.';
          }

          return true;
        },
      ],
      labelPreviewHeaders: {
        'x-api-host': this.$apiHostAndProtocol,
      },
      changedLabelType: null,
      changeLabelTypesDialog: false,
      changeLabelTypeLoading: false,
      overrideStartDate: false,
      overrideStartDateDialog: false,
      retailStartDate: undefined, // undefined so the default in the component will be used
      adalHelper: new AdalHelpers(),
      deleteItemsDialog: false,
      deleteItemsLoading: false,
      isLabelAdmin: false,
      changingBatchItem: false,
      priceEditBefore: null,
      priceEditOpen: false,
      priceEditValid: false,
      showOverrideRetailStartDateWarning: false,
    };
  },
  watch: {
    // whenever batchInfo changes, this function will run
    batchInfo: {
      async handler() {
        if (this.batchInfo.printImmediately) {
          await this.submitPrint();
        } else {
          this.changedLabelType = null;
          await this.getData();
        }
      },
      deep: true,
    },
    'batchInfoItems.allSelected': {
      async handler() {
        // everything is deselected, set that explicitly.
        if (
          this.batchInfoItems.allSelected === false &&
          this.batchInfoItems.allSelectedIndeterminate === false
        ) {
          await this.$authApi.http.post(
            `label/batchinfoitem/deselectall/${this.batchInfo.batchInfoId}`,
          );
        }
      },
    },
    batchInfoItems: {
      async handler() {
        this.$emit('input', this.packageBatchCheckItems());
      },
      deep: true,
    },
    selectedApprovedTypes: {
      async handler() {
        this.$emit('input', this.packageBatchCheckItems());
      },
      deep: true,
    },
    selectedSvgTemplates: {
      async handler() {
        this.$emit('input', this.packageBatchCheckItems());
      },
      deep: true,
    },
    batchInfoItemSearch: {
      async handler() {
        this.batchInfoItems.onPageFilter = () => this.batchInfoItemsAdditionalFilter();
        this.$emit('input', this.packageBatchCheckItems());
      },
      deep: true,
    },
    svgTemplatesForBatch: {
      async handler() {
        this.$emit('input', this.packageBatchCheckItems());
      },
      deep: true,
    },
    overrideStartDateDialog: {
      async handler(val) {
        if (val === false && this.overrideStartDate === true) {
          this.showOverrideRetailStartDateWarning = true;
        }
      },
    },
  },
  async mounted() {
    if (this.batchInfo.printImmediately) {
      await this.submitPrint();
    } else {
      await this.getData();
    }
  },
  async created() {
    // get svg templates
    let bulkFilter = '';
    if (this.bulkOnlyLabelTypes !== null) {
      bulkFilter = `&$filter=isBulkTemplate eq ${this.bulkOnlyLabelTypes}`;
    }

    this.svgTemplates = (
      await this.$authApi.http.get(
        `label/svgtemplate?$orderby=displayOrder&$select=templateId,description,isBulkTemplate${bulkFilter}`,
      )
    ).data;

    this.isLabelAdmin = await this.$authApi.roleHasRights('LabelAdmin');
  },
  computed: {
    ...mapState('app', ['username']),
    labelTextInitalCaps() {
      return (
        this.labelText[0].toUpperCase() +
        this.labelText.substring(1, this.labelText.length).toLowerCase()
      );
    },
    labelTextLowerCase() {
      return this.labelText.toLowerCase();
    },
    showBatchInfoItems() {
      return this.batchInfo && this.batchInfoItems && this.batchInfo.printImmediately !== true;
    },
    printDisabled() {
      return (
        this.printing === true ||
        this.batchInfoItems.items.length === 0 ||
        this.changingBatchItem === true
      );
    },
    deleteItemsDisabled() {
      return this.batchInfoItems.selected.length === 0 || this.batchInfoItems.allSelected === true;
    },
    deleteItemsPlural() {
      return this.batchInfoItems.selected.length === 1 ? '' : 's';
    },
    changeLabelTypeDisabled() {
      return (
        (this.batchInfoItems.allSelected === false && this.batchInfoItems.selected.length === 0) ||
        this.changeLabelTypeLoading === true
      );
    },
    changeLabelTypeText() {
      return `Change ${
        this.batchInfoItems.allSelected && this.batchInfoItems.selected.length > 1 ? 'All' : ''
      } ${this.labelTextInitalCaps} Type${this.batchInfoItems.selected.length === 1 ? '' : 's'}`;
    },
  },
  methods: {
    packageBatchCheckItems() {
      const bci = new BatchCheckItems();
      bci.batchInfoItems = this.batchInfoItems;
      bci.approvedTypes = this.selectedApprovedTypes;
      bci.svgTemplates = this.selectedSvgTemplates;
      bci.availableSvgTemplates = this.svgTemplatesForBatch;
      bci.search = this.batchInfoItemSearch;
      return bci;
    },
    setupEvents() {
      if (this.batchInfoItems && this.batchInfoItems.removeListener) {
        const error = () => {
          this.$emit('error', 'Error getting items');
        };

        const dataBound = () => {
          this.$emit('dataBound', this.batchInfoItems.items);

          if (this.batchInfoItems.items && this.batchInfoItems.items.length > 0) {
            // eslint-disable-next-line no-restricted-syntax
            for (const item of this.batchInfoItems.items) {
              Vue.set(item, 'previewIsActive', false);
              Vue.set(item, 'previewIsLoading', false);
              Vue.set(item, 'svgTemplateIdInternal', item.svgTemplateId);
            }

            this.labelPreviewItem = this.batchInfoItems.items[0];
          }
        };

        this.batchInfoItems.removeListener('error', error);
        this.batchInfoItems.removeListener('dataBound', dataBound);
        this.batchInfoItems.on('error', error);
        this.batchInfoItems.on('dataBound', dataBound);
      }
    },
    resetPrintImmediately() {
      if (this.batchInfo.printImmediately === true) {
        // reset printImmediately prop
        const batchInfoLocal = Object.assign({}, this.batchInfo);
        batchInfoLocal.printImmediately = false;
        this.$emit('update:batchInfo', batchInfoLocal);
      }
    },
    batchInfoItemsAdditionalFilterCore() {
      let filter = f('and');

      // everything is selected or nothing selected
      if (!this.selectedApprovedTypes || this.selectedApprovedTypes.length === 0) {
        // nothing selected
        filter = filter.notIn('approved', [true, false]);
      } else {
        filter = filter.in(
          'approved',
          this.selectedApprovedTypes.map(s => s.toLowerCase() === 'yes'),
        );
      }

      // everything is selected or nothing selected
      if (!this.selectedSvgTemplates || this.selectedSvgTemplates.length === 0) {
        // nothing selected
        filter = filter.notIn(
          'svgTemplateId',
          this.svgTemplatesForBatch.map(s => s.templateId),
        );
      } else {
        filter = filter.in(
          'svgTemplateId',
          this.selectedSvgTemplates.map(s => s.templateId),
        );
      }

      return filter;
    },
    batchInfoItemsAdditionalFilter() {
      let filter = this.batchInfoItemsAdditionalFilterCore();

      if (this.batchInfoItemSearch && this.batchInfoItemSearch.length > 0) {
        const trimmedSearch = this.batchInfoItemSearch.trim();
        filter = filter.and(
          f('or')
            .contains("'upc'", trimmedSearch)
            .contains("'itemNumber'", trimmedSearch)
            .contains("'description1'", trimmedSearch)
            .contains("'description2'", trimmedSearch),
        );
      }

      return filter;
    },
    async getData(allSelectedOverride, force) {
      if (!this.value || this.value.inited !== true || force) {
        this.batchInfoItems = this.initBatchInfoItems(allSelectedOverride);
        this.batchInfoItems.loading = true;
        this.setupEvents();

        await this.getSvgTemplateTypesForBatch();

        if (allSelectedOverride !== null && allSelectedOverride !== undefined) {
          this.batchInfoItems.allSelected = allSelectedOverride;
        }

        await this.getBatchInfoItems();
      } else {
        this.selectedApprovedTypes = this.value.approvedTypes;
        this.selectedSvgTemplates = this.value.svgTemplates;
        this.svgTemplatesForBatch = this.value.availableSvgTemplates;
        this.batchInfoItemSearch = this.value.search;

        this.batchInfoItems = this.value.batchInfoItems;
        this.setupEvents();
      }
    },
    async svgTemplateChanged(event, item) {
      try {
        this.changingBatchItem = true;
        item.previewIsLoading = true;

        const selectedTemplates = this.svgTemplates.filter(x => x.templateId === event);
        // check to see if this is a bulk template
        if (
          selectedTemplates &&
          selectedTemplates.length === 1 &&
          selectedTemplates[0].isBulkTemplate === true
        ) {
          // check to see if the the item is elligable for bulk
          const ingredTest = await this.$authApi.http.get(
            `label/ingredprintstore/$count?$filter=upc eq '${item.upc.trim()}'`,
          );
          if (ingredTest.data === 0) {
            // since the upc isn't in ingredprintstore, show the dialog
            this.bulkTemplateDialog = true;
            this.changingBatchItem = false;

            // reset template type to what's in the db
            const currTemplateId = await this.$authApi.http.get(
              `label/batchinfoitem/${item.batchInfoItemId}`,
            );
            if (currTemplateId && currTemplateId.data) {
              item.svgTemplateId = currTemplateId.data.svgTemplateId;
            }
            return;
          }
          this.changeAllTemplateChange(this.changedLabelType);
        }

        // update batch info row svg template
        await this.$authApi.http.patch(`label/batchinfoitem/${item.batchInfoItemId}`, {
          svgTemplateId: event,
        });

        const updatedItem = await this.$authApi.http.put(
          `label/batchinfoitem/recalculatepricing/${item.batchInfoId}/${item.batchInfoItemId}`,
        );

        this.getSvgTemplateTypesForBatch();
        this.changingBatchItem = false;

        item.svgTemplateId = event;
        item.svgTemplateIdInternal = event;

        // only update the keys in updatedItem.data
        // we add a few props to the item, for vue stuff
        for (const key of Object.keys(updatedItem.data)) {
          if (key in item) {
            item[key] = updatedItem.data[key];
          }
        }

        // reload the label preview
        item.reload();
        item.previewIsLoading = false;
      } catch (err) {
        item.reload();
        item.previewIsLoading = false;

        this.$emit('snackbar-error', {
          text: 'Error changing print type.',
          err,
          id: 'bad90e99-7d65-41af-b96b-f0d6cfbc10c4',
        });
      }
    },
    changeAllTemplateChange(event) {
      if (!event) {
        this.showChangeAllBulkTemplateMessage = false;
        return;
      }
      const selectedTemplates = this.svgTemplates.filter(x => x.templateId === event);
      // check to see if this is a bulk template, if so, show message
      this.showChangeAllBulkTemplateMessage =
        selectedTemplates &&
        selectedTemplates.length === 1 &&
        selectedTemplates[0].isBulkTemplate === true;
    },
    async selectBatchInfoItemsAll(event) {
      if (event) {
        this.batchInfoItems.selected = this.batchInfoItems.items;
      } else {
        this.batchInfoItems.selected = [];
      }
    },
    async getBatchInfoItems(force) {
      const currentSelectAll = this.batchInfoItems.allSelected;

      if (force === true) {
        // this will force a refresh
        this.batchInfoItems._lastAdditionalFilter = null;
      }
      await this.batchInfoItems.get(this.batchInfoItemsAdditionalFilter(), force);

      if (currentSelectAll === true && this.batchInfoItems.allSelected !== true) {
        this.batchInfoItems.allSelected = true;
      }
    },
    async changeApproved(force) {
      await this.getBatchInfoItems(force);
      await this.getSvgTemplateTypesForBatch();
    },
    openPresentationQtyEdit(item) {
      // clone the item
      this.batchItemEditBefore = Object.assign({}, item);
      this.printSequenceEditOpen = true;
    },
    closePresentationQtyEdit(item) {
      this.printSequenceEditOpen = false;
      if (this.presentationQtyEditValid === false) {
        // Set back the print sequence
        this.batchInfoItems.items.filter(
          i => i.batchInfoItemId === item.batchInfoItemId,
        )[0].presentationQty = this.batchItemEditBefore.presentationQty;
      }
    },
    cancelPresentationQtyEdit() {
      this.printSequenceEditOpen = false;
    },
    async savePresentationQtyEdit(item) {
      try {
        this.presentationQtyEditValid = this.presentationQtyRules[0](item.presentationQty) === true;

        if (this.presentationQtyEditValid) {
          await this.$authApi.http.patch(`label/batchinfoitem/${item.batchInfoItemId}`, {
            presentationQty: item.presentationQty,
          });

          // set the update result in the grid
          const batchInfoItem = this.batchInfoItems.items.filter(
            i => i.batchInfoItemId === item.batchInfoItemId,
          )[0];
          // reload the label preview
          batchInfoItem.reload();
        }
      } catch (e) {
        this.$error(e);
        this.$emit('error', 'Error updating presentation quantity.');

        // Reset the grid
        this.batchInfoItems = this.initBatchInfoItems();
        this.setupEvents();
        this.batchInfoItems.get(this.batchInfoItemsAdditionalFilter());
      }
    },
    async updateSelected(allSelected) {
      // first update "header" row for batch info
      await this.$authApi.http.post(
        `label/batchinfoitem/allselected/${this.batchInfo.batchInfoId}/${allSelected}`,
      );

      const limit = pLimit(this.$maxConcurrentRequests);

      const patches = [];
      if (allSelected !== true) {
        // now set rows individually, if they need it.
        this.batchInfoItems.trackedDataKeys.forEach(async key => {
          patches.push(
            limit(async () => {
              await this.$authApi.http.patch(
                `label/batchinfoitem/${this.batchInfoItems.trackedData[key].item.batchInfoItemId}`,
                { selected: this.batchInfoItems.trackedData[key]._selected },
              );
            }),
          );
        });
      }

      await Promise.all(patches);
    },
    async submitPrint() {
      try {
        this.printing = true;

        // if we're going to print immediately init the batchInfoItems object with
        // allSelected set to true
        const allSelected = this.batchInfo.printImmediately
          ? true
          : this.batchInfoItems.allSelected;

        if (this.batchInfo.printImmediately) {
          this.selectedApprovedTypes = this.approvedTypes;
          this.selectedSvgTemplates = await this.getSvgTemplateTypesForBatch();
        }

        await this.updateSelected(allSelected);

        // Check to see if anything will actually be printed.
        const filter = this.batchInfoItemsAdditionalFilterCore()
          .eq('selected', true)
          .eq('batchInfoId', this.batchInfo.batchInfoId);
        const selectedCount = await this.$authApi.http.get(
          `label/batchinfoitem/$count?$filter=${filter.toString()}`,
        );
        if (selectedCount.data > 0) {
          const startPrint = batchInfo => {
            const apprTypes = [];
            if (this.selectedApprovedTypes) {
              if (this.selectedApprovedTypes.indexOf('Yes') > -1) {
                apprTypes.push(true);
              }
              if (this.selectedApprovedTypes.indexOf('No') > -1) {
                apprTypes.push(false);
              }
            }

            const footerInformation = new FooterInformation(
              batchInfo,
              this.footerBatchAndId,
              this.overrideStoreNumber || this.$store.state.app.storeNumber,
            );

            if (this.hideBatchNameInFooter === true) {
              footerInformation.batchName = null;
            }

            let includeSetDelimters = this.includeSetDelimters;
            if (this.showIncludeSetDelimtersCheckbox === true) {
              includeSetDelimters = this.includeSetDelimtersOverride;
            }

            const renderLabelData = new RenderLabelData(
              batchInfo,
              this.startingPosition,
              this.forceNewRender,
              includeSetDelimters,
              apprTypes,
              this.selectedSvgTemplates.map(s => s.templateId),
              this.username,
              footerInformation,
              null,
              this.additionalCompleteUpdate,
              this.autoCompleteBatch,
              this.showCompleteToggle,
              this.pageBreakOnNewStoreSet,
              this.originatingPage,
              false,
            );

            this.resetPrintImmediately();

            // Navigate to progress page
            Router.push({
              name: 'sl_render_progress',
              params: {
                batchInfo: renderLabelData.base64RenderData(),
              },
            });
          };

          const errorMessage = () => {
            this.printing = false;
            this.$emit('error', 'Error creating retail start date override batch.');
          };

          // If they decided to override the retail start date.
          // Then, we need to create a new batch based off the current one
          // and print the new batch.
          if (this.overrideStartDate) {
            const adalToken = await this.adalHelper.getJwtToken(this.$authApi);
            const batchFromBatchId = uuid();
            let batchCreated = false;

            const onClose = () => {
              // If the web socket connection closed before
              // the batch was created throw an error message.
              if (batchCreated === false) {
                errorMessage();
              }
            };

            // Register on message handler before posting the message
            const onmessage = event => {
              const recMessage = JSON.parse(event.data);
              batchCreated = true;

              this.batchFromBatchWs.close();

              if (recMessage.success === false) {
                errorMessage();
                return;
              }

              this.createdBatchInfo = recMessage.batchInfo;

              // We've created the new batch, now pull in the info from the table.
              this.$authApi.http
                .get(`label/batchinfo/${this.createdBatchInfo.batchInfoId}`)
                .then(res => {
                  startPrint(res.data);
                })
                .catch(() => {
                  errorMessage();
                });
            };

            const onOpen = async () => {
              // Request the batch to be created
              const batchFromBatchData = {
                id: batchFromBatchId,
                storeNumber: this.overrideStoreNumber || this.$store.state.app.storeNumber,
                batchInfoId: this.batchInfo.batchInfoId,
                retailStartDate: this.retailStartDate,
              };
              await this.$authApi.http.post('servicebus/batchfrombatch', batchFromBatchData);
            };

            this.batchFromBatchWs = new PogonaWebSocket(
              this.$apiBasePath,
              'batch_from_batch',
              batchFromBatchId,
              adalToken,
              onClose,
              onmessage,
              null,
              onOpen,
            );
          } else {
            startPrint(this.batchInfo);
          }
        } else {
          this.zeroItemsDialog = true;
          this.printing = false;
        }
      } catch (e) {
        this.$error(e);
        this.$emit('error', 'Error printing, try again.');
        this.printing = false;
      }
    },
    getSvgTemplateTypesForBatch() {
      let approvedQuery = '';
      if (!this.selectedApprovedTypes || this.selectedApprovedTypes.length === 1) {
        approvedQuery = `?approved=${this.selectedApprovedTypes[0].toLowerCase() === 'yes'}`;
      }
      return new Promise((resolve, reject) => {
        this.$authApi
          .http(
            `label/labeldata/batch/${this.batchInfo.batchInfoId}/svgTemplateTypes${approvedQuery}`,
          )
          .then(svgTemplateTypes => {
            this.svgTemplatesForBatch = svgTemplateTypes.data;
            this.selectedSvgTemplates = this.svgTemplatesForBatch;

            if (this.svgTemplatesForBatch && this.svgTemplatesForBatch.length === 1) {
              this.changedLabelType = this.svgTemplatesForBatch[0].templateId;
            } else {
              this.changedLabelType = null;
            }

            this.changeAllTemplateChange(this.changedLabelType);

            this.setIncludePogHeadersCheckbox();

            resolve(svgTemplateTypes.data);
          })
          .catch(e => {
            this.$emit('error', 'Error getting items');
            reject(e);
          });
      });
    },
    setIncludePogHeadersCheckbox() {
      // if the "Include POG header" is shown, set it based on this result
      if (this.showIncludeSetDelimtersCheckbox === true) {
        this.includeSetDelimtersOverride = true;
        // eslint-disable-next-line no-restricted-syntax
        for (const svgTemplateType of this.svgTemplatesForBatch) {
          if (svgTemplateType.excludeDelimiters === true) {
            this.includeSetDelimtersOverride = false;
            break;
          }
        }
      }
    },
    async removeSelectedItems() {
      const finishup = async () => {
        this.deleteItemsLoading = false;
        this.deleteItemsDialog = false;
        await this.getData(null, true);
      };
      try {
        if (this.batchInfoItems.allSelected === true) {
          throw new Error('All items are selected, cannot delete.');
        }
        this.deleteItemsLoading = true;

        this.batchInfoItems.selected.forEach(async selected => {
          await this.$authApi.http.delete(`label/batchinfoitem/${selected.batchInfoItemId}`);
        });

        await finishup();
      } catch (err) {
        this.$emit('snackbar-error', {
          text: 'Error deleting items.',
          err,
          id: '0459d491-4ad4-4848-bf0f-00e2c464a4b8',
        });
        await finishup();
      }
    },
    openPriceEdit(item) {
      // clone the item
      this.priceEditBefore = Object.assign({}, item);
      this.priceEditOpen = true;
    },
    closePriceEdit(item) {
      this.priceEditOpen = false;
      if (this.priceEditValid === false) {
        // Set back the print sequence
        const row = this.batchInfoItems.items.filter(
          i => i.batchInfoItemId === item.batchInfoItemId,
        )[0];

        if (row) {
          row.signLabelRetail = this.priceEditBefore.signLabelRetail;
          row.discountAmount = this.priceEditBefore.discountAmount;
          row.discountPercent = this.priceEditBefore.discountPercent;
        }
      }
    },
    cancelSignLabelRetailEdit() {
      this.priceEditOpen = false;
    },
    newUnitPriceIsValid(item) {
      let unitPriceEditValid = true;
      let newUnitPrice = 0;
      // do some quick calculations to see if the unit price will be > than 0
      if (item.discountAmount) {
        newUnitPrice = (item.signLabelRetail - item.discountAmount) / item.size;
      } else if (item.discountPercent) {
        newUnitPrice = (item.signLabelRetail * (item.discountPercent / 100.0 - 1) * -1) / item.size;
      } else {
        newUnitPrice = item.signLabelRetail / item.size;
      }

      if (newUnitPrice < 0.01) {
        this.$emit('error', {
          text: `Cannot update price. '${this.$filters.formatDecimal(
            item.signLabelRetail,
          )}' would result in a negative unit price.`,
          err: null,
          id: '1a0a0550-b222-44f4-ada0-80873dc34bd0',
        });
        unitPriceEditValid = false;
      }
      return unitPriceEditValid;
    },
    async saveSignLabelRetailEdit(item) {
      try {
        let unitPriceEditValid = isNaN(item.signLabelRetail) === false;
        unitPriceEditValid = this.newUnitPriceIsValid(item);

        if (unitPriceEditValid === true) {
          const result = await this.$authApi.http.patch(
            `label/batchinfoitem/${item.batchInfoItemId}`,
            {
              signLabelRetail: this.$filters.formatDecimal(item.signLabelRetail),
            },
          );

          // set the update result in the grid
          const batchInfoItem = this.batchInfoItems.items.filter(
            i => i.batchInfoItemId === item.batchInfoItemId,
          )[0];

          batchInfoItem.signLabelRetail = result.data.signLabelRetail;
          // reload the label preview
          batchInfoItem.reload();
        }
      } catch (err) {
        this.$error(err);
        this.$emit('snackbar-error', {
          text: 'Error setting retail price.',
          err,
          id: '5c1eb5f7-0478-4a2e-9708-c129cfc5be2d',
        });
      }
    },
    async saveDiscountAmountEdit(item) {
      try {
        if (!item.discountAmount) {
          item.discountAmount = 0.0;
        }

        let unitPriceEditValid = isNaN(item.discountAmount) === false;
        unitPriceEditValid = this.newUnitPriceIsValid(item);

        if (unitPriceEditValid === true) {
          item.discountPercent = 0;
          const result = await this.$authApi.http.patch(
            `label/batchinfoitem/${item.batchInfoItemId}`,
            {
              discountAmount: this.$filters.formatDecimal(item.discountAmount),
              discountPercent: this.$filters.formatDecimal(item.discountPercent),
            },
          );

          // set the update result in the grid
          const batchInfoItem = this.batchInfoItems.items.filter(
            i => i.batchInfoItemId === item.batchInfoItemId,
          )[0];

          batchInfoItem.discountAmount = result.data.discountAmount;
          batchInfoItem.discountPercent = result.data.discountPercent;
          // reload the label preview
          batchInfoItem.reload();
        }
      } catch (err) {
        this.$error(err);
        this.$emit('snackbar-error', {
          text: 'Error setting discount amount.',
          err,
          id: '58aa0d04-13ff-4459-ba73-70f68aabf8d4',
        });
      }
    },
    async saveDiscountPercentEdit(item) {
      try {
        if (!item.discountPercent) {
          item.discountPercent = 0.0;
        }

        let unitPriceEditValid = isNaN(item.discountPercent) === false;
        unitPriceEditValid = this.newUnitPriceIsValid(item);

        if (unitPriceEditValid === true) {
          item.discountAmount = 0;
          const result = await this.$authApi.http.patch(
            `label/batchinfoitem/${item.batchInfoItemId}`,
            {
              discountPercent: this.$filters.formatDecimal(item.discountPercent),
              discountAmount: this.$filters.formatDecimal(item.discountAmount),
            },
          );

          // set the update result in the grid
          const batchInfoItem = this.batchInfoItems.items.filter(
            i => i.batchInfoItemId === item.batchInfoItemId,
          )[0];

          batchInfoItem.discountPercent = result.data.discountPercent;
          batchInfoItem.discountAmount = result.data.discountAmount;
          // reload the label preview
          batchInfoItem.reload();
        }
      } catch (err) {
        this.$error(err);
        this.$emit('snackbar-error', {
          text: 'Error setting discount percent.',
          err,
          id: '9e0bba3a-5013-4978-b982-49be5f1b2783',
        });
      }
    },
    async setApprovedOnHand() {
      this.setApprovedOnHandDialogLoading = true;
      try {
        const result = await this.$authApi.http.post(
          `label/batchinfoitem/setapprovedonhand/${this.batchInfo.batchInfoId}`,
        );

        this.batchInfo.itemApprovedCount = result.data.itemApprovedCount;

        this.setApprovedOnHandDialog = false;
        this.setApprovedOnHandDialogLoading = false;

        await this.getBatchInfoItems(true);
      } catch (err) {
        this.setApprovedOnHandDialogLoading = false;
        this.$emit('error', {
          text: 'Error setting approved from on-hand quantities.',
          err,
          id: '1f9c2e7f-ab59-4c9e-8900-cb6b06901659',
        });
      }
    },
    async checkForDiscountsForBatch() {
      this.checkForDiscountsDialogLoading = true;
      try {
        await this.$authApi.http.post(
          `label/batchinfo/checkdiscounts/${this.batchInfo.batchInfoId}`,
        );
        this.checkForDiscountsDialog = false;
        this.checkForDiscountsDialogLoading = false;

        await this.getSvgTemplateTypesForBatch();
        await this.getBatchInfoItems(true);
      } catch (err) {
        this.checkForDiscountsDialogLoading = false;
        this.$emit('error', {
          text: 'Error checking for discounts.',
          err,
          id: '9218aec2-96e8-4d60-9c68-70f12de4ca01',
        });
      }
    },
    async resetTemplatesForBatch() {
      this.resetTemplatesDialogLoading = true;
      try {
        await this.$authApi.http.post(
          `label/batchinfo/resettemplates/${this.batchInfo.batchInfoId}`,
        );
        this.resetTemplatesDialog = false;
        this.resetTemplatesDialogLoading = false;

        await this.getSvgTemplateTypesForBatch();
        await this.getBatchInfoItems(true);
      } catch (err) {
        this.resetTemplatesDialogLoading = false;
        this.$emit('error', {
          text: 'Error setting resetting templates.',
          err,
          id: 'a12d9da8-a9f8-45cd-b8a4-f3d8f19765a1',
        });
      }
    },
    initBatchInfoItems(allSelectedOverride) {
      // Only set selectedApprovedTypes if it hasn't been set yet
      if (!this.selectedApprovedTypes || this.selectedApprovedTypes.length === 0) {
        this.selectedApprovedTypes = [this.approvedTypes[0]];
      }
      const allSelected =
        allSelectedOverride !== null && allSelectedOverride !== undefined
          ? allSelectedOverride
          : this.batchInfo.allSelected;

      const batchInfoItems = new PogonaDataTable({
        headers: [
          {
            text: 'UPC #',
            value: 'upc',
          },
          {
            text: 'Item #',
            value: 'itemNumber',
          },
          {
            text: 'Description 1',
            value: 'description1',
          },
          {
            text: 'Description 2',
            value: 'description2',
          },
          {
            text: 'Case Qty',
            value: 'baseQuantity',
          },
          {
            text: 'Size',
            value: 'size',
          },
          {
            text: 'UOM',
            value: 'unitOfMeasure',
          },
          {
            text: 'R/Type',
            value: 'rtype',
          },
          {
            text: 'Base Retail',
            value: 'signLabelRetail',
          },
          {
            text: 'Preview',
            value: 'preview',
            sortable: false,
          },
          {
            text: 'Print Type',
            value: 'svgTemplateId',
          },
          {
            text: 'Discount $',
            value: 'discountAmount',
          },
          {
            text: 'Discount %',
            value: 'discountPercent',
          },
          {
            text: 'Promotion Description',
            value: 'discountDescription',
          },
          {
            text: 'Print Qty',
            value: 'presentationQty',
          },
          {
            text: 'Approved',
            value: 'approved',
          },
        ],
        baseUrl: 'label/batchinfoitem',
        defaultFilter: f('and').eq('batchInfoId', this.batchInfo ? this.batchInfo.batchInfoId : 0),
        multiselect: true,
        allSelected,
        allSelectedIndeterminate: allSelected === false,
        httpClient: this.$authApi.http,
        options: { itemsPerPage: 50, sortBy: ['printSequence', 'batchInfoItemId'] },
        onPageFilter: () => this.batchInfoItemsAdditionalFilter(),
        keys: ['batchInfoItemId'],
        selectedKey: 'selected',
        isInfinite: true,
      });

      return batchInfoItems;
    },
    async changeLabelTypes() {
      try {
        this.changeLabelTypeLoading = true;

        await this.updateSelected(this.batchInfoItems.allSelected);

        const changeTemplateCalls = [];
        if (this.batchInfoItems.allSelected === true) {
          changeTemplateCalls.push(
            this.$authApi.http.post(
              `label/batchinfoitem/changealltemplates/${this.batchInfo.batchInfoId}/${this.changedLabelType}`,
              { recalculatePricing: true },
            ),
          );
        } else {
          this.batchInfoItems.selected.forEach(async selected => {
            changeTemplateCalls.push(
              this.$authApi.http.patch(`label/batchinfoitem/${selected.batchInfoItemId}`, {
                svgTemplateId: this.changedLabelType,
              }),
            );

            changeTemplateCalls.push(
              this.$authApi.http.put(
                `label/batchinfoitem/recalculatepricing/${selected.batchInfoId}/${selected.batchInfoItemId}`,
              ),
            );
          });
        }

        if (changeTemplateCalls && changeTemplateCalls.length > 0) {
          await Promise.all(changeTemplateCalls);
        }

        this.changeLabelTypeLoading = false;
        this.changeLabelTypesDialog = false;

        await this.getData(this.batchInfoItems.allSelected, true);
      } catch (e) {
        this.changeLabelTypeLoading = false;
        this.$emit('error', 'Error changing label type');
        this.$error(e);
      }
    },
  },
});

export default BatchCheckItemsComponent;
export { BatchCheckItemsComponent };
</script>

<style lang="scss">
.scrollable {
  max-height: 45em !important;
}

.batch-items .scrollable .v-data-table__wrapper {
  max-height: inherit !important;
}
</style>
