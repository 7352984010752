<template>
  <core-view-template title="Store Login Mappings">
    <core-view-section title="Mapped Users">
      <v-row>
        <v-col>
          <v-text-field
            prepend-icon="search"
            v-model="storeLoginMappingSearch"
            label="Search (username)"
            :clearable="true"
            @click:clear="
              storeLoginMappingSearch = null;
              searchStoreLogins();
            "
            v-debounce:300="searchStoreLogins"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mb-2">
        <v-col cols="12">
          <v-data-table
            v-if="storeLoginMappings"
            v-model="storeLoginMappings.selected"
            :headers="storeLoginMappings.headers"
            :items="storeLoginMappings.items"
            :loading="storeLoginMappings.loading"
            :items-per-page="50"
            :disable-pagination="true"
            :fixed-header="true"
            :options.sync="storeLoginMappings.options"
            @update:sort-by="storeLoginMappings.get"
            @click:row="storeLoginMappings.itemRowSingleClicked($event, storeLoginMappings)"
            @item-selected="storeLoginMappings.itemRowSingleSelected($event, storeLoginMappings)"
            item-key="storeLoginId"
            class="elevation-1 scrollable"
            show-select
            :single-select="true"
          >
            <template v-slot:top>
              <v-toolbar>
                <v-dialog v-model="newUserMappingDialog" max-width="800px">
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on">New User Mapping</v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">New User Mapping</span>
                    </v-card-title>
                    <v-card-text>
                      <ad-users-search v-model="selectedNewUser"></ad-users-search>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        @click="addNewUserMapping"
                        :disabled="!selectedNewUser || newUserMappingLoading"
                        :loading="newUserMappingLoading"
                        >Save</v-btn
                      >
                      <v-btn @click="newUserMappingDialog = false">Cancel</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.delete="{ item }">
              <v-icon @click="showDeleteDialog(item)">delete</v-icon>
            </template>

            <template v-slot:body.append>
              <infinite-loader
                v-if="storeLoginMappings && storeLoginMappings.inited === true"
                :pogonaTable="storeLoginMappings"
                :additionalFilter="storeLoginMappingsAdditionalFilter"
                :colspan="storeLoginMappings.headers.length"
              ></infinite-loader>
            </template>
          </v-data-table>
          <infinite-paganation :pogonaTable="storeLoginMappings"></infinite-paganation>
        </v-col>
      </v-row>
      <v-row v-if="deleteUserMappingDialog">
        <v-dialog v-model="deleteUserMappingDialog" max-width="500">
          <v-card>
            <v-card-title>
              <span class="headline">Delete User Mapping</span>
            </v-card-title>
            <v-card-text>
              Are you sure you want to delete user mapping '{{
                deleteUserMappingItem ? deleteUserMappingItem.username : ''
              }}'?
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click="deleteUserMap(deleteUserMappingItem)"
                :disabled="deleteUserMappingLoading"
                :loading="deleteUserMappingLoading"
                >Yes</v-btn
              >
              <v-btn @click="deleteUserMappingDialog = false">No</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </core-view-section>
    <core-view-section title="Mapped Stores">
      <v-row class="mb-2">
        <v-col cols="12">
          <v-data-table
            v-if="mappedStores"
            v-model="mappedStores.selected"
            :headers="mappedStores.headers"
            :items="mappedStores.items"
            :loading="mappedStores.loading"
            :items-per-page="50"
            :disable-pagination="true"
            :fixed-header="true"
            :options.sync="mappedStores.options"
            @update:sort-by="mappedStores.get"
            @click:row="mappedStores.itemRowMultiClicked($event, mappedStores)"
            @item-selected="mappedStores.itemRowMultiSelected($event, mappedStores)"
            item-key="storeNumber"
            class="elevation-1 scrollable"
            show-select
          >
            <template v-slot:top>
              <v-row>
                <v-col cols="12" xl="6" lg="6" md="6">
                  <store-mappings
                    v-model="newStoresToMap"
                    @saved="addNewUserStoreMappings"
                    :mappedStores="mappedStoresProjected"
                    :loading="newUserStoreMappingLoading"
                    :dialog.sync="newUserStoreMappingDialog"
                  ></store-mappings>
                </v-col>
                <v-col cols="12" xl="6" lg="6" md="6">
                  <v-dialog v-model="deleteUserStoreMappingDialog" max-width="500px">
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" :disabled="mappedStores.selected.length < 1">
                        Delete Selected Mapping{{ mappedStores.selected.length === 1 ? '' : 's' }}
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="headline">Delete Selected Mappings</span>
                      </v-card-title>
                      <v-card-text>
                        Are you sure you want to delete the selected mapping{{
                          mappedStores.selected.length === 1 ? '' : 's'
                        }}?
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          @click="deleteUserStoreMappings"
                          :disabled="deleteUserStoreMappingLoading"
                          :loading="deleteUserStoreMappingLoading"
                          >Yes</v-btn
                        >
                        <v-btn @click="deleteUserStoreMappingDialog = false">No</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
            </template>
            <template v-slot:item.isDefault="{ value }">{{ value ? 'Yes' : 'No' }}</template>
            <template v-slot:item.setAsDefault="{ item }">
              <v-btn :disabled="item.isDefault" @click="openSetAsDefaultDialog(item)"
                >Set as Default</v-btn
              >
            </template>
          </v-data-table>
          <infinite-paganation :pogonaTable="mappedStores"></infinite-paganation>
        </v-col>
      </v-row>

      <v-dialog v-model="setAsDefaultDialog" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">Set as default</span>
          </v-card-title>
          <v-card-text>
            Are you sure you want to set
            {{ setAsDefaultItem ? setAsDefaultItem.storeNumber : '' }} as the default store for user
            {{ selectedUser ? selectedUser.username : '' }}?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="setMappedStoreAsDefault"
              :disabled="setAsDefaultLoading"
              :loading="setAsDefaultLoading"
              >Yes</v-btn
            >
            <v-btn @click="setAsDefaultDialog = false">No</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </core-view-section>
  </core-view-template>
</template>

<script>
import AdUsersSearch from './AdUsersSearch.vue';
import StoreMappings from './StoreMappings.vue';
import PogonaDataTable from '@/utils/PogonaDataTable';
import f from 'odata-filter-builder';
import { mapState } from 'vuex';

export default {
  components: {
    AdUsersSearch,
    StoreMappings,
  },
  data() {
    return {
      storeLoginMappings: null,
      newUserMappingDialog: false,
      newUserMappingLoading: false,
      selectedNewUser: null,
      deleteUserMappingDialog: false,
      deleteUserMappingItem: null,
      deleteUserMappingLoading: false,
      mappedStores: null,
      newUserStoreMappingLoading: false,
      newUserStoreMappingDialog: false,
      deleteUserStoreMappingDialog: false,
      deleteUserStoreMappingLoading: false,
      newStoresToMap: [],
      setAsDefaultDialog: false,
      setAsDefaultLoading: false,
      setAsDefaultItem: null,
      storeLoginMappingSearch: null,
    };
  },
  async created() {
    await this.initStoreLoginMappings();
  },
  computed: {
    ...mapState('app', ['username', 'storeMappings', 'storeNumber']),
    storeLoginMappingsAdditionalFilter() {
      let filter = f('and');
      if (this.storeLoginMappingSearch && this.storeLoginMappingSearch.length > 0) {
        const trimmedSearch = this.storeLoginMappingSearch.trim();
        filter = filter.contains("'username'", trimmedSearch);
      }

      return filter;
    },
    selectedUser() {
      return this.storeLoginMappings &&
        this.storeLoginMappings.selected &&
        this.storeLoginMappings.selected.length === 1
        ? this.storeLoginMappings.selected[0]
        : null;
    },
    mappedStoresProjected() {
      return this.mappedStores.items.map(x => x.storeNumber);
    },
  },
  methods: {
    showDeleteDialog(item) {
      this.deleteUserMappingItem = item;
      this.deleteUserMappingDialog = true;
    },
    async deleteUserMap(item) {
      try {
        this.deleteUserMappingLoading = true;
        // first get StoreLoginStoreMappings
        const storeLoginStoreMappings = (
          await this.$authApi.http.get(
            `pogona/storeloginstoremapping?$filter=storeLoginId eq ${item.storeLoginId}`,
          )
        ).data;
        // delete mappings
        const deletes = [];

        storeLoginStoreMappings.forEach(slsm => {
          deletes.push(
            this.$authApi.http.delete(
              `pogona/storeloginstoremapping/${slsm.storeLoginId}/${slsm.storeNumber}`,
            ),
          );
        });
        await Promise.all(deletes);

        // delete StoreLogin
        await this.$authApi.http.delete(`pogona/storelogin/${item.storeLoginId}`);

        this.deleteUserMappingLoading = false;
        this.deleteUserMappingDialog = false;
        await this.initStoreLoginMappings();
        this.mappedStores = null;
      } catch (err) {
        this.deleteUserMappingLoading = false;
        this.$emit('snackbar-error', {
          text: 'Failed to delete user mapping',
          err,
          id: '2b0b6920-747b-4656-943e-afb8473354b7',
        });
      }
    },
    async addNewUserMapping() {
      try {
        this.newUserMappingLoading = true;

        await this.$authApi.http.post('pogona/storelogin', {
          username: this.selectedNewUser.userPrincipalName,
        });

        this.newUserMappingLoading = false;
        this.newUserMappingDialog = false;

        await this.initStoreLoginMappings();
      } catch (err) {
        this.newUserMappingLoading = false;
        this.$emit('snackbar-error', {
          text: 'Error creating user mapping',
          err,
          id: '60868276-8a80-42b7-a0a3-22db3dc7d1b5',
        });
      }
    },
    async addNewUserStoreMappings() {
      try {
        this.newUserStoreMappingLoading = true;

        const posts = [];
        const currentStoreMappings = this.storeMappings || [];
        this.newStoresToMap.forEach(store => {
          const newStoreMapping = {
            storeLoginId: this.selectedUser.storeLoginId,
            storeNumber: store.storeNumber,
            isDefault: false,
          };
          posts.push(this.$authApi.http.post('pogona/storeloginstoremapping', newStoreMapping));
          currentStoreMappings.push(newStoreMapping);
        });

        await Promise.all(posts);

        // if the user is editing themselves, update the footer mappings
        if (this.username === this.selectedUser.username) {
          this.$store.dispatch(
            'app/storeMappings',
            currentStoreMappings.sort((x, y) => x.storeNumber - y.storeNumber),
          );
        }

        this.newUserStoreMappingLoading = false;
        this.newUserStoreMappingDialog = false;
        this.newStoresToMap = [];
        await this.initUserStoreMappings();
      } catch (err) {
        this.newUserStoreMappingLoading = false;
        this.$emit('snackbar-error', {
          text: 'Failed to save user store mappings',
          err,
          id: '3ccb663f-cd46-489a-8cf6-28c48d080320',
        });
      }
    },
    async deleteUserStoreMappings() {
      try {
        this.deleteUserStoreMappingLoading = true;

        let setDefault = false;
        const deletes = [];
        const currentStoreMappings = this.storeMappings || [];
        this.mappedStores.selected.forEach(mappedStore => {
          deletes.push(
            this.$authApi.http.delete(
              `pogona/storeloginstoremapping/${mappedStore.storeLoginId}/${mappedStore.storeNumber}`,
            ),
          );

          // splice out the mapping we want to delete
          let mix = 0;
          for (; mix < currentStoreMappings.length; mix += 1) {
            // if they're deleting their current store, handle that later
            if (this.storeNumber === mappedStore.storeNumber) {
              setDefault = true;
            }

            if (currentStoreMappings[mix].storeNumber === mappedStore.storeNumber) {
              break;
            }
          }
          currentStoreMappings.splice(mix, 1);
        });

        await Promise.all(deletes);

        // if the user is editing themselves, update the footer mappings
        if (this.username === this.selectedUser.username) {
          const sortedMappings = currentStoreMappings.sort((x, y) => x.storeNumber - y.storeNumber);
          this.$store.dispatch('app/storeMappings', sortedMappings);

          if (setDefault === true) {
            let selectedStore = sortedMappings[0].storeNumber;
            const defaultMapping = sortedMappings.filter(x => x.isDefault === true);
            if (defaultMapping && defaultMapping.length === 1) {
              selectedStore = defaultMapping[0].storeNumber;
            }
            this.$store.dispatch('app/setStoreNumber', selectedStore);
          }
        }

        this.deleteUserStoreMappingLoading = false;
        this.deleteUserStoreMappingDialog = false;

        await this.initUserStoreMappings();
      } catch (err) {
        this.deleteUserStoreMappingLoading = false;
        this.$emit('snackbar-error', {
          text: 'Failed to delete user store mappings',
          err,
          id: '356a58f5-4e36-42d0-98f3-014c34b9878a',
        });
      }
    },
    openSetAsDefaultDialog(item) {
      this.setAsDefaultItem = item;
      this.setAsDefaultDialog = true;
    },
    async setMappedStoreAsDefault() {
      try {
        this.setAsDefaultLoading = true;

        await this.$authApi.http.post('pogona/storeloginstoremapping/setdefault', {
          storeLoginId: this.setAsDefaultItem.storeLoginId,
          storeNumber: this.setAsDefaultItem.storeNumber,
        });

        this.setAsDefaultLoading = false;
        this.setAsDefaultDialog = false;

        await this.initUserStoreMappings();

        // if the user is editing themselves, set the store to the selected one
        if (this.username === this.selectedUser.username) {
          this.$store.dispatch('app/setStoreNumber', this.setAsDefaultItem.storeNumber);
        }
      } catch (err) {
        this.setAsDefaultLoading = false;
        this.$emit('snackbar-error', {
          text: 'Failed to set store as default',
          err,
          id: 'f8cae8e6-087b-47c6-8fe9-27a485370c50',
        });
      }
    },
    async initStoreLoginMappings() {
      this.storeLoginMappings = new PogonaDataTable({
        headers: [
          {
            text: 'Email',
            value: 'username',
          },
          {
            text: 'Delete',
            value: 'delete',
            sortable: false,
          },
        ],
        baseUrl: 'pogona/storelogin',
        httpClient: this.$authApi.http,
        options: { itemsPerPage: 50, sortBy: ['username'] },
        additionalFilter: this.storeLoginMappingsAdditionalFilter,
        isInfinite: true,
        keys: ['storeLoginId'],
      });

      this.storeLoginMappings.on('error', err => {
        this.$emit('snackbar-error', {
          text: 'Error getting store login mappings',
          err,
          id: 'fcf1360b-0b5a-404b-93f9-5d338b8b9b01',
        });
      });

      await this.searchStoreLogins();
    },
    async searchStoreLogins() {
      if (this.storeLoginMappings && this.storeLoginMappings.inited) {
        await this.storeLoginMappings.get(this.storeLoginMappingsAdditionalFilter);
      }
    },
    async initUserStoreMappings() {
      this.mappedStores = new PogonaDataTable({
        headers: [
          {
            text: 'Store Number',
            value: 'storeNumber',
          },
          {
            text: 'Default Store',
            value: 'isDefault',
          },
          {
            text: 'Set as default',
            value: 'setAsDefault',
            sortable: false,
          },
        ],
        baseUrl: 'pogona/storeloginstoremapping',
        defaultFilter: f('and').eq('storeLoginId', this.selectedUser.storeLoginId),
        httpClient: this.$authApi.http,
        options: { itemsPerPage: 50, sortBy: ['storeNumber'] },
        isInfinite: true,
        multiselect: true,
        keys: ['storeLoginId', 'storeNumber'],
      });

      this.mappedStores.on('error', err => {
        this.$emit('snackbar-error', {
          text: 'Error getting mapped stores',
          err,
          id: '38ac560f-7eef-4b4f-87c6-0b98aeefe36d',
        });
      });

      await this.mappedStores.get();
    },
  },
  watch: {
    'storeLoginMappings.selected': {
      handler: async function (val) {
        if (val && val.length === 1) {
          await this.initUserStoreMappings();
        }
      },
    },
  },
};
</script>
