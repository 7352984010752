<template>
  <v-autocomplete
    v-model="selectedStores"
    :items="stores"
    :clearable="true"
    item-text="storeNumber"
    item-value="storeNumber"
    :label="`Select ${multiple ? 'one or more stores' : 'a store'}`"
    @change="storeChanged"
    :multiple="multiple"
    prepend-icon="house_siding"
  ></v-autocomplete>
</template>

<script>
import f from 'odata-filter-builder';
import buildQuery from 'odata-query';

export default {
  props: {
    value: {
      type: [Array, String],
      required: true,
      default() {
        return [];
      },
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    icon: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selectedStores: this.value,
      stores: [],
    };
  },
  methods: {
    storeChanged() {
      this.$emit('input', this.selectedStores);
      this.$emit('change', this.selectedStores);
    },
  },
  async created() {
    // get all stores from store info bridge
    let storeTemp = null;

    const storePageSize = 200;
    let page = 0;

    do {
      const odataOptions = {
        filter: f('and')
          .gt(x => x.length('storeNumber'), 0)
          .le(x => x.length('storeNumber'), 4)
          .toString(),
        top: storePageSize,
        skip: storePageSize * page,
        orderby: 'storeNumber',
      };
      const builtOdataQuery = buildQuery(odataOptions);

      // eslint-disable-next-line no-await-in-loop
      storeTemp = (await this.$authApi.http.get(`label/storeinfobridge${builtOdataQuery}`)).data;

      storeTemp.forEach(i => {
        this.stores.push(i.storeNumber);
      });

      page += 1;
    } while (storeTemp === null || storeTemp.length > 0);
  },
};
</script>
